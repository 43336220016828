import { Button } from "../../@/components/ui/button";
import { DataTable } from "./data-table";
import { useRef, useState } from "react";
import Modal from "../../components/Modal";
import { ColumnDef } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../@/components/ui/dropdown-menu";
import { ReactComponent as MoreVertical } from "../../assets/icons/more-vertical.svg";
import moment from "moment";
import {
  Links,
  SortHeader,
  SortIcon,
  Status,
  SubscriptionTitle,
} from "./columns";
import { UserDetails } from "./UserDetails";
import { useDebounce } from "../../helpers/useDebounce";
import { UserFilterView } from "./components/components/UserFilterView";
import BlockedUsers from "./BlockedUsers";
import { api } from "../../helpers/api";
import { useToast } from "../../@/components/ui/use-toast";
import Spinner from "../../components/Spinners";
import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  SortAscIcon,
  SortDescIcon,
} from "lucide-react";
import { subscriptionList } from "../../helpers/consts";

export const getTotalSellers = (tier: string, subscriptionList: any) => {
  return subscriptionList.find((sub: any) => sub.tier === tier)?.sellers || 0;
};

type User = {
  id: string;
  name: string;
  email: string;
  joinedOn: string;
  tier: string;
  totalSellers: number;
  status: string;
  statusText: string;
  statusDescription: string;
  totalSpend: string;
  stripeCustomerId: string;
  subscription: any;
  openDrawer: (row: any) => void;
  deleteModal: (row: any) => void;
  deactivateModal: (row: any) => void;
};

export default function Users() {
  const [drawer, setDrawer] = useState(false);
  // const toggleDrawer = () => setDrawer(!drawer);
  const openDrawer = () => setDrawer(true);
  const closeDrawer = () => setDrawer(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState<any>(null);
  const [deactivateModal, setDeactivateModal] = useState<any>(false);
  const [search, setSearch] = useState<string>("");
  const [showDeactivateUserView, setShowDeactivateUserView] = useState(false);
  const toggleDeleteModal = (user: any) => setDeleteModal(user);
  const toggleDeactivateModal = (user: any) => setDeactivateModal(user);
  const debouncedSearch = useDebounce(search, 500);
  const [filters, setFilters] = useState<any>([]);
  const refetchRef = useRef<any>(null);
  const [sortBy, setSortBy] = useState<"name" | "plan" | "joinedOn" | null>(
    null
  );
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | null>(null);

  const columns: ColumnDef<User>[] = [
    {
      accessorKey: "fullName",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => {
              if (sortBy === "name") {
                setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
              } else {
                setSortBy("name");
                setSortOrder("ASC");
              }
            }}
            className="p-0"
          >
            Name
            {sortBy === "name" ? (
              sortOrder === "ASC" ? (
                <ArrowUpIcon className="w-4 h-4 rotate-[180deg] ml-2" />
              ) : (
                <ArrowUpIcon className="w-4 h-4 ml-2" />
              )
            ) : (
              <ArrowUpDownIcon className="w-4 h-4 ml-2" />
            )}
          </Button>
        );
      },
    },
    {
      accessorKey: "planType",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => {
              if (sortBy === "plan") {
                setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
              } else {
                setSortBy("plan");
                setSortOrder("ASC");
              }
            }}
            className="p-0"
          >
            Plan
            {sortBy === "plan" ? (
              sortOrder === "ASC" ? (
                <ArrowUpIcon className="w-4 h-4 rotate-[180deg] ml-2" />
              ) : (
                <ArrowUpIcon className="w-4 h-4 ml-2" />
              )
            ) : (
              <ArrowUpDownIcon className="w-4 h-4 ml-2" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        return row.original.tier ? (
          <SubscriptionTitle
            tier={row.original.subscription.tier || row.original.tier}
          />
        ) : (
          "-"
        );
      },
    },
    {
      accessorKey: "totalSpend",
      header: "Total spend",
      cell: ({ row }) => {
        return <span>{parseInt(row.original?.totalSpend)}</span>;
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        if (row.original.subscription) {
          const { isActive, cancelledAt, updatedAt } =
            row.original.subscription;

          let text = "-";
          let status = "";
          let statusDescription = "";

          if (isActive && !cancelledAt) {
            if (moment().isAfter(moment(updatedAt).add(1, "month"))) {
              // If current time is after the renewal date
              text = `Past due on ${moment(updatedAt).format(
                "YYYY-MM-DD HH:mm"
              )}`;
              status = "past_due";
              statusDescription = `${
                row.original.email
              } subscription is past due since ${moment(updatedAt).format(
                "YYYY-MM-DD HH:mm"
              )}`;
            } else {
              const renewDate = moment(updatedAt).add(1, "month").fromNow();
              // Otherwise, show the renews in text
              text = `Renews in ${
                renewDate === "in a month" ? "a month" : renewDate
              }`;
              status = "active";
              statusDescription = `${
                row.original.email
              } renews their subscription on ${moment(updatedAt)
                .add(1, "month")
                .format("YYYY-MM-DD HH:mm")}`;
            }
          } else if (cancelledAt) {
            // If the subscription is not active and it is cancelled
            text = `Churned on ${moment(cancelledAt).format(
              "YYYY-MM-DD HH:mm"
            )}`;
            status = "churned";
            statusDescription = `${
              row.original.email
            } churned their subscription on ${moment(cancelledAt).format(
              "YYYY-MM-DD HH:mm"
            )}`;
          } else if (!isActive && moment().isAfter(moment(updatedAt))) {
            // If the subscription is not active and the date is past
            text = `Past due on ${moment(updatedAt).format(
              "YYYY-MM-DD HH:mm"
            )}`;
            status = "past_due";
            statusDescription = `${
              row.original.email
            } subscription is past due since ${moment(updatedAt).format(
              "YYYY-MM-DD HH:mm"
            )}`;
          }

          return (
            <Status
              status={[
                {
                  status: status,
                  statusText: text,
                  statusDescription: statusDescription,
                },
              ]}
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      accessorKey: "totalSellers",
      header: "Total sellers",
      cell: ({ row }) => {
        return (
          <span>
            {row.original.totalSellers}/
            {getTotalSellers(row?.original?.tier, subscriptionList)}
          </span>
        );
      },
    },
    {
      accessorKey: "links",
      header: "Links",
      cell: ({ row }) => {
        return (
          <Links
            links={[
              {
                email: row.original.email,
                stripeCustomerId: row.original.stripeCustomerId,
              },
            ]}
          />
        );
      },
    },
    {
      accessorKey: "joinedOn",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => {
              if (sortBy === "joinedOn") {
                setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
              } else {
                setSortBy("joinedOn");
                setSortOrder("ASC");
              }
            }}
            className="p-0"
          >
            Joined on
            {sortBy === "joinedOn" ? (
              sortOrder === "ASC" ? (
                <ArrowUpIcon className="w-4 h-4 rotate-[180deg] ml-2" />
              ) : (
                <ArrowUpIcon className="w-4 h-4 ml-2" />
              )
            ) : (
              <ArrowUpDownIcon className="w-4 h-4 ml-2" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        return (
          <span>
            {moment(row.original.joinedOn).utc().format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="[&>div]:cursor-pointer">
              <DropdownMenuItem
                onClick={() => {
                  openDrawer();
                  setSelectedRow(row?.original);
                }}
              >
                View details
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => toggleDeactivateModal(row?.original)}
              >
                Deactivate user
              </DropdownMenuItem>
              <DropdownMenuItem
                className="text-red-700 font-[500] hover:!text-red-700"
                onClick={() => toggleDeleteModal(row.original)}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div>
      <UserDetails
        open={drawer}
        closeDrawer={closeDrawer}
        selectedRow={selectedRow}
        toggleDeactivateModal={() => toggleDeactivateModal(selectedRow)}
      />
      <DeleteModal
        open={!!deleteModal}
        handleModal={(refresh?: boolean) => {
          if (refresh && refetchRef.current) {
            refetchRef.current();
          }
          setDeleteModal(false);
        }}
        user={deleteModal}
      />
      <DeactivateModal
        open={!!deactivateModal}
        handleModal={(refresh?: boolean) => {
          if (refresh && refetchRef.current) {
            refetchRef.current();
          }
          setDeactivateModal(false);
        }}
        user={deactivateModal}
      />
      {showDeactivateUserView && (
        <BlockedUsers
          open={showDeactivateUserView}
          onClose={() => setShowDeactivateUserView(false)}
        />
      )}

      <div className="border-slate-300 justify-between items-center w-full border-b-solid border-b-[1px] py-[0.875rem] px-[0.9375rem] h-[3.25rem] flex">
        <h1 className="text-[1.125rem] font-[500]">Manage users</h1>
        <Button
          onClick={() => setShowDeactivateUserView(true)}
          className="!h-7"
          variant={"outline"}
        >
          Deactivated Users
        </Button>
      </div>
      <div className="flex flex-col p-[1.125rem] gap-5">
        <UserFilterView
          search={search}
          setSearch={setSearch}
          filters={filters}
          onClear={() => setFilters({})}
          onChange={(updates) => {
            setFilters({
              ...filters,
              ...updates,
            });
          }}
        />
        <DataTable
          columns={columns}
          search={debouncedSearch}
          filters={filters}
          onRefetchLoad={(refetch) => (refetchRef.current = refetch)}
          onRowClick={(row) => {
            openDrawer();
            setSelectedRow(row);
          }}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      </div>
    </div>
  );
}

function DeleteModal({
  open,
  handleModal,
  user,
}: {
  open: boolean;
  handleModal: (flag?: boolean) => void;
  user: any;
}) {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const handleDelete = () => {
    setLoading(true);

    api
      .deleteUser(user.id)
      .then(() => {
        setLoading(false);

        handleModal(true);
        toast({
          description: "User deleted successfully",
        });
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
        toast({
          description:
            error?.response?.data?.message || "Failed to delete user",
          variant: "destructive",
        });
      });
  };
  return (
    <Modal open={open} title="" noClose handleModal={handleModal}>
      <h2 className="font-grotesk text-black text-[1.25rem]">Delete user</h2>
      <p className="mt-3">
        Are you sure you want to permanently delete the user “{user?.fullName}”?{" "}
      </p>
      <div className="flex flex-row gap-3 mt-[1.125rem] justify-end">
        <Button
          disabled={loading}
          variant="outline"
          onClick={() => handleModal()}
        >
          Go back
        </Button>
        <Button
          disabled={loading}
          onClick={handleDelete}
          className="flex items-center gap-1"
          variant="delete"
        >
          <span>Yes, delete</span>

          {loading && <Spinner />}
        </Button>
      </div>
    </Modal>
  );
}
function DeactivateModal({
  open,
  handleModal,
  user,
}: {
  open: boolean;
  handleModal: (flag?: boolean) => void;
  user: any;
}) {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const handleDeactivate = () => {
    setLoading(true);
    api
      .updateUserActive(user.id, false)
      .then(() => {
        setLoading(false);

        handleModal(true);
        toast({
          description: "User deactivated",
        });
      })
      .catch((error) => {
        setLoading(true);

        console.log(error);
        toast({
          description:
            error?.response?.data?.message || `Failed to deactivate user`,
          variant: "destructive",
        });
      });
  };
  return (
    <Modal open={open} title="" noClose handleModal={handleModal}>
      <h2 className="font-grotesk text-black text-[1.25rem]">
        Deactivate user
      </h2>
      <p className="mt-3">
        Are you sure you want to deactivate the user “{user?.fullName}”?{" "}
      </p>
      <div className="flex flex-row gap-3 mt-[1.125rem] justify-end">
        <Button
          disabled={loading}
          variant="outline"
          onClick={() => handleModal()}
        >
          Go back
        </Button>
        <Button
          disabled={loading}
          onClick={handleDeactivate}
          className="flex items-center gap-1"
          variant="delete"
        >
          <span>Yes, deactivate</span>
          {loading && <Spinner />}
        </Button>
      </div>
    </Modal>
  );
}
